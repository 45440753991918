<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p class='nomargin'><b> На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Пластик La Doll</li>
                <li>Кисть синтетическая, плоская</li>
                <li>Стеки</li>
                <li>Карандаш</li>
                <li>Чертеж</li>
                <li>Напильник, шкурка разной зернистости</li>
                <li>Макетный нож</li>
                <li>Следок</li>
                <li>Вода</li>
            </ol>   
        </div>

        <VideoView video-id="2e4242516a784b66a35281ad131ade85"/>


        <div class="MBtextcont">
            <p class='nomargin'><b>Задание:</b></p>
            <p>Проработать ногу и отшкурить. Придать ей окончательны вид.</p>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>